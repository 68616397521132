@import "../../utils/includes.scss";

// ========
// Fonts
// ========

$fontFamily: "Roboto", sans-serif;
$boldFontFamily: "Montserrat", sans-serif;
$boldFontWeight: 700;
$titleFontFamily: $boldFontFamily;
$titleFontWeight: $boldFontWeight;
$isUppercase: uppercase;

// ========
// Colors
// ========

$textColor: #000000;
$linkColor: #000000;
$labelColor: #aaaaaa;
$formColor: #333333;
$utilColor: #000000;
$priceColor: #BF2D2C;
$lightColor: #ffffff;
$btnColorAlpha: #ffffff;
$btnColorBeta: #ffffff;
$btnColorStep: #ffffff;
$titleColor: #000000;
$titleSecondaryColor: #ffffff;
$subtitleColor: #000000;
$subtitleSecondaryColor: #ffffff;
$successColor: #2DB253;
$warningColor: #f2bf24;
$errorColor: #BF2D2C;

$bgPrimaryColor: #ffffff;
$bgSecondaryColor: #f2f2f2;

$bgBtnAlpha: #000000;
$colorBtnAlpha: #ffffff;
$bgBtnBeta: #BF2D2C;
$colorBtnBeta: #ffffff;
$colorBtnOutlineAlpha: #111111;
$colorBtnOutlineBeta: #111111;
$paddingButtonPrimary: 16px 14px;
$paddingButtonSecondary: 11px 15px;
$borderRadiusButton: 0px;

$borderRadiusForm: 0px;
$borderColorForm: #000;
$borderSizeForm: 1px solid;

$calculatorTabsColor: #111111;
$calculatorTabsTextColor: #ffffff;
$calculatorCloseTabs: #4d4d4d;
$calculatorCloseTabsColor: #686869;
$calculatorMainColor: #ffffff;
$calculatorButton: #BF2D2C;

$garageBackground: #ffffff;
$garageTextColor: #333333;
$garageCarNoteColor: #f8f8f8;

$bgTopbarLogin: #BF2D2C;
$colorTopbarLogin: #ffffff;

$progressBarTitleBG: #000000;
$progressBarTitleColor: #ffffff;
$progressBarTitleCloseBG: #000000;
$progressBarTitleCloseColor: #ffffff;
$progressBarCloseButtonBG: #000000;
$progressBarCloseButtonColor: #ffffff;

$paymentCalculatorClosedBG: #BF2D2C;
$paymentCalculatorClosedColor: #e8e8e8;
$paymentCalculatorPanelBG: #444444;
$paymentCalculatorTermWrapperBG: #BBBBBB;
$paymentCalculatorTermWrapperColor: #808080;
$paymentCalculatorTermSelectedWrapperBG: #ffffff;
$paymentCalculatorTermSelectedColor: #ffffff;
$paymentCalculatorTermBG: #333333;
$paymentCalculatorTermColor: #aaaaaa;

// ========
// Styles
// ========
.widget-sr{
    @include clearfix;
    width: 100%;
    &.dealer__mega-centre-occasion{
        font-family: $fontFamily;
        font-size: 14px;
        color: $textColor;
        background-color: $bgPrimaryColor;
        &.Checkout{
            background-color: $bgSecondaryColor
        }
        .listing-used{
            background-color: $bgPrimaryColor;
        }
        .listing-tile-note, .listing-tile-note .vehicle-note{
            background: $bgPrimaryColor;
        }
        .listing-used .listing-used-inner-content .listing-used-header-refine button,
        .listing-used-payment .listing-used-set-budget button{
            color: $lightColor;
        }
        .listing-used-payment-wrap .InputText .input-control,
        .listing-used-payment-wrap .styled-placeholder.dropdown .Select-control,
        .listing-used-payment-wrap .InputNumber.light-theme input,
        .listing-used-payment-wrap .InputNumber.light-theme button{
            color: #000000;
            border-color: #000000;
        }
        .listing-used-payment-wrap .styled-placeholder.dropdown.not-empty .input-label,
        .listing-used-payment-wrap .Select.has-value.is-pseudo-focused.Select--single>.Select-control .Select-value .Select-value-label,
        .listing-used-payment-wrap .Select.has-value.Select--single>.Select-control .Select-value .Select-value-label,
        .listing-used-payment-wrap .InputNumber.light-theme .InputNumber--label,
        .listing-used-payment-wrap .InputText.styled-placeholder.not-empty .input-label,
        .listing-used-payment-wrap .InputText .input-control::placeholder,
        .listing-used-filters-category-list .sr-link.category-link.sr-text,
        .listing-used-results .number-results,
        .listing-used-filters-bloc.listing-used-metadata-container .metadata-list .metadata-button,
        .listing-button-wrapper .model-button,
        .listing-used-filters-bloc.listing-used-filters-drivetrain .listing-used-drivetrain label.sr-text{
            color: #000000;
        }
        .widget-login .widget-login-desktop{
            padding: 0;
        }
        .purchase-methods-cash .purchase-detail-line.price_total{
            border: 0px;
            background-color: #BF2D2C;
        }    
        #calculator-toggle-button.sr-button-1{
            background: #000000;
            &:hover{
                background: #000000;
                opacity: 1;
            }
        }
        .sr-paymentCalculator-dropdown .is-focused .Select-control,
        .sr-paymentCalculator-dropdown .Select-control,
        .sr-paymentCalculator-dropdown .Select-option{
            background-color: #333333 !important;
        }
        .paymentCalculator__paymentsInfos{
            .paymentCalculator__paymentsWrapper{
                .paymentCalculator__payment{
                    span.is-util{
                        color: #ffffff;
                    }
                }
            }
            .paymentCalculator__paymentsWrapper>:after{
                background: #000000;
            }
            .paymentCalculator__rebatesWrapper{
                .paymentCalculator__rebates--term .icon-promo2.is-util{
                    color: #ffffff;
                }
            }
        }
        .calculatorColumn{
            border-right: 1px solid $paymentCalculatorTermWrapperBG;
        }
        .calculator-tradeIn, .new-customize-payment, .calculator-cash-down, .section-distance{
            border-bottom: 1px solid $paymentCalculatorTermWrapperBG;
        }
        .calculator-done{
            border-top: 1px solid $paymentCalculatorTermWrapperBG;
        }
        .calculatorColumn .purchase-detail-line .is-util,
        .calculatorColumn .calculator-tradeIn .sr-link {
            color: $calculatorMainColor;
        }
        .calculator-term.selected .taxesIncluded-section.is-light .payment-detail.is-light span{
            color: $priceColor;
        }
        .new-customize-payment .taxesIncluded-section.is-light span.is-util{
            color: $calculatorMainColor;
        }
        .style-color__button.selected{
            border: 3px solid $textColor;
        }

        .bloc-options__single--footer.selected .option-choose-cta {
            background-color: $textColor;
            border-color: $textColor;
        }

        .listing-used-bodystyle.selected.is-price:before {
            color: $utilColor;
        }

        .vehicleBanner-personsViewed{
            background: rgba(0,0,0,0.75);
            color: #ffffff;
        }

        .vehicleBanner-photos .vehicleBanner-photo-button{
            background: $paymentCalculatorTermWrapperColor;
        }

        .protection-single--content .protection-cta-selector.selected {
            background-color: $textColor;
            color: $textColor;
            border-color: $textColor;
        }
        .widget-garage .vehicle-wrapper-info .vehicle-transaction {
            color: $utilColor;
        }

        .widget-sr input[type=radio]:checked+label:after{
            background: $utilColor!important;
        }

        .widget-profile-popupElement form#profileForm [type=checkbox]:checked+label:before, .widget-profile-popupElement form#profileForm input[type=radio]:checked+label:before,
        .widget-sr input[type=radio]:checked+label:before{
            border-color: $utilColor!important;
        }

        .section-catalog-filter .catalog-filter-selection .catalog-filter-type .catalog-filter-type-list--single.selected {
            color: $utilColor;
        }
        .listing-used-filters-bloc.listing-used-metadata-container .metadata-list .metadata-button.selected{
            color: $utilColor;
            border-color: $utilColor;
        }
        .sr-modal__wrapper div.style-specs__modal-close-bloc{
            overflow: auto;
        }
        .TransactionProgressBar--wrapper{
            background-color: $colorTopbarLogin;
        }
        .TransactionProgressBar--progression{
            background-color: $textColor;
        }
        .HeaderMessage.message-handler.warning{
            background-color: $errorColor;
        }
        .listing-tile .listing-tile-car-save.has-warning-background{
            background-color: #000000;
        }
        .listing-tile .tile-tag.picto-showroom.is-bold{
            background: #BF2D2C !important;
        }
        .TransactionSummaryUnit--single .validFlag icon{
            color: #000000;
        }
        .TransactionSummary--wrapper--title:not(.is-open)+.TransactionSummaryUnit--wrapper .TransactionSummaryUnit--single.is-validate{
            border-bottom-color: $colorTopbarLogin;
        }
        .TransactionSummaryUnit--single .validFlag:before{
            background-image: -webkit-linear-gradient(304deg,transparent 50%,$colorTopbarLogin 55%);
        }
        .car-number.is-light,
        .carfax-content .is-light,
        .carfax-logo .is-light,
        .certified-text.is-light{
            color: $textColor;
        }
        .listing-tile{
            .listing-tile-image .car-tagline{
                color: $lightColor;
            }
            .listing-tile-all-price .disclaimer-price.is-light{
                color: $textColor;
            }
            .listing-tile-footer{
                width: 100%;
                display: flex;
                -ms-flex-wrap: nowrap;
                flex-wrap: nowrap;
                padding: 20px;
                justify-content: flex-start;
                align-items: center;
                .listing-tile-origin{
                    width: 70%;
                    padding: 0 0 0 8px;
                    -ms-flex-item-align: center;
                    align-self: center;
                    justify-content: flex-end;
                    -ms-flex-pack: end;
                    .icon-map{
                        font-size: 15px;
                    }
                    p{
                        font-size: 11px;
                    }
                    &.is-light{
                        color: $textColor;
                    }
                }
                .vehicle-certification{
                    padding: 0;
                    width:30%;
                    .carfax-icon{
                        margin-right: 0;
                    }
                    .certification-badge{
                        justify-content: flex-start;
                        -ms-flex-pack: start;
                        img{
                            max-width: 100%;
                        }
                        .certification-badge__image{
                            margin-bottom: 8px;
                        }
                        .certification-badge-secondary{
                            margin-top: 0;
                        }
                    }
                }
            }
        }
        .listing-new-tile{
            .listing-tile-car-save,
            .listing-tile-package-description,
            .listing-new-tile-spec .listing-tile-specification-specifics .listing-tile-specification-stock,
            .listing-new-tile-drivePowerTrains,
            .listing-tile-vin,
            .listing-tile-note .sr-link,
            .listing-tile-note .vehicle-note .VehicleDisclaimersContent p,
            .listing-tile-all-price-info .disclaimer-price.is-light{
                color: #000000;
            }
        }
        .listing-tiles-payment{
            .financing-section.tile-payment-info{
                .tile-payment-rate,
                .tile-payment-detail{
                    color: #000000;
                }
            }
        }
        .VehicleDisclaimersContent p{
            color: #000000;
        }
        .listing-used-payment-search .input-search input::placeholder{
            color: #4d4d4d;
        }
        .cash-section.tile-payment-info{
            .payment-row{
                color: #000000;
                p.sr-text{
                    color: #000000;
                }
            }
            .payment-row-section .payment-row-price{
                &.sr-text{
                    color: #000000;
                }
            }
        }
        .listing-tile-all-price-title.sr-text{
            color: #000000;
        }
        .tile-payment-info .payment-rebates .payment-rebate{
            color: #000000;
            .rebate-title .icon-promo2.is-primary{
                color: #000000;
            }
        }
        .TradeIn__wrapper{
            .is-light{
                color: #4d4d4d;
            }
        }
        .sr-calculator-tabs{
            li.current{
             border-color: #4d4d4d !important;
            }
        }
        .sr-garage,
        .checkout__content-right-section{
            .is-light{
                color: #000000;
            }
        }
        .tile-payment-cashdown:last-child{
            font-size: 11px !important;
            color: #999999 !important;
            .tile-payment-cashdown-note{
                font-size: 11px !important;
            }
        }
        .sr-modal--white-close{
            color: #666666;
        }
        .section-showroom-header__progressBar-stage.selected{
            color: #fff;
            .progressBar-stage__active-effect{
                background-color: #BF2D2C !important;
                fill: #BF2D2C !important;
            }
        }
        .section-showroom-header__progressBar-stage{
            color: #4d4d4d;;
            border-color: #4d4d4d;;
        }
        .section-showroom-header__progressBar-stage .progressBar-stage__content .icon-showroom{
            color: #4d4d4d;;
        }
        .section-showroom-header__progressBar-stage.selected .progressBar-stage__content .icon-showroom{
            color: #4d4d4d;;
        }
        .financing-section.tile-payment-info .tile-payment-rate .frequency-highlight,
        .vehicle-overview__vehicle-title .vehicle-stockno, .vehicle-overview__vehicle-title .vehicle-vinno{
            color: #4d4d4d;
        }
        .disclaimer-price.is-light{
            color: #999999;
        }
        // **********************
        // Header B&B
        // **********************
        @include headerBuildBuy;

        // **********************
        // Barre de progression
        // **********************
        @include progressBar;

        // **********************
        // Formulaires
        // **********************
        @include forms;

        // **********************
        // Par sections
        // **********************
        @include paymentCalculator;
        @include checkout;
        @include sidebarsummary;
        @include login;
        @include garage;
        @include protections;
        
        @include titles;
        @include buttons;
        @include links;
        @include generics;
        @include baseline;
    }
}

@include breakpoint(mobile){
    .widget-sr{
        &.dealer__mega-centre-occasion{
            .LoginBanner .LoginBannerWrapper{
                height: 34px;
                max-height: 34px;
                .login-content{
                    display: flex;
                    #button-login.btn-login{
                        margin-left: 0;
                    }
                    #button-register.btn-register{
                        margin-left: 10px;
                    }
                }
            }
            .vehicle-tile-rebate-single{
                background: $bgBtnAlpha;
                .is-price{
                    color: $colorBtnAlpha;
                }
                span{
                    color: $colorBtnAlpha;
                }
            }
        }
    }
}